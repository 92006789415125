import { ReactNode } from 'react'

export const AVOID_ADDING_PARAGRAPH_TAG_LIST = ['div', '$$']

const ParagraphMDX = ({ children, ...props }: { children?: ReactNode }) => {
  const getCircularReplacer = () => {
    const seen = new WeakSet()
    return (key: string, value: string | object) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return
        }
        seen.add(value)
      }
      return value
    }
  }

  const checkIfChildrenHaveSpecificHTMLTags = () => {
    const stringifiedChildren = JSON.stringify(children, getCircularReplacer())
    const hasTags =
      children &&
      AVOID_ADDING_PARAGRAPH_TAG_LIST.some((tag) =>
        stringifiedChildren.includes(tag)
      )
    return hasTags
  }

  return checkIfChildrenHaveSpecificHTMLTags() ? (
    <div data-testid="content-div">{children}</div>
  ) : (
    <p {...props} data-testid="content-p">
      {children}
    </p>
  )
}

export default ParagraphMDX
