import MarkdownText from '../MarkdownText/MarkdownText'
import './style.scss'

interface IFootnote {
  text: string
  id?: string
}

interface IFootnotes {
  footnotes: IFootnote[]
}

const Footnotes = ({ footnotes }: IFootnotes) => {
  const scrollToFootnoteText = (link?: string) => {
    if (document && document.querySelector && link)
      document
        .querySelector(`a[href='#${link}']`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  return (
    <div className="footnotes">
      <h2 className="title ff-roboto">Footnotes</h2>
      {footnotes.map(({ text, id }: IFootnote, index: number) => {
        return (
          <div className="footnote-item" key={index + 1}>
            <div className="mb-0" id={id}>
              <button
                data-testid="footnote-button"
                onClick={() => scrollToFootnoteText(id)}
              >
                {index + 1}
              </button>
            </div>
            <MarkdownText
              data-testid="footnotes-text"
              text={text}
              className="text-color-third ml-9"
            />
          </div>
        )
      })}
    </div>
  )
}

export default Footnotes
