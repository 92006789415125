import MarkdownText from '../MarkdownText/MarkdownText'

import './style.scss'

interface INotes {
  text: string
  title: string
  type: 'primary' | 'success' | 'error' | 'warning'
}

const Notes = ({ text, title, type = 'primary' }: INotes) => {
  return (
    <div
      data-testid="note-container"
      className={`card-body notes notes-${type}`}
    >
      <h3 className="notes-title">{title}</h3>
      <MarkdownText text={text} className="text-color-third m-0" />
    </div>
  )
}

export default Notes
