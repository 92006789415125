/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    hr: "hr",
    code: "code",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Qiskit Runtime is a service built on IBM Cloud that offers the ability to execute quantum workflows on IBM hardware and simulators all within the Qiskit software development kit."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Qiskit Runtime ships with Qiskit, which is IBM's open-source quantum software development kit (SDK) built in Python. This means that when Qiskit is installed via ", React.createElement(_components.code, null, "pip install qiskit"), ", Qiskit Runtime is also installed and can be imported as ", React.createElement(_components.code, null, "import qiskit_ibm_runtime"), "."), "\n", React.createElement(_components.p, null, "Accessing hardware or simulators available with Qiskit Runtime requires that you ", React.createElement(_components.a, {
    href: "https://cloud.ibm.com/login?redirect=/catalog/services/quantum-computing"
  }, "create an IBM Cloud account"), ", which starts you off with a small amount of free credits to help get you started. Paid plans are available once the free credits have been used. A full list of available compute resources can be found on the ", React.createElement(_components.a, {
    href: "https://cloud.ibm.com/quantum/resources/systems"
  }, "IBM Cloud website"), "."), "\n", React.createElement(_components.p, null, "Qiskit Runtime is aimed towards workflows requiring many iterations and hybrid quantum/classical processes, such as quantum machine learning and quantum chemistry problems. This is made possible by its integration with ", React.createElement(_components.a, {
    href: "https://pennylane.ai/qml/glossary/what-are-qiskit-primitives/"
  }, "the new V2 primitives"), " and ", React.createElement(_components.a, {
    href: "https://pennylane.ai/qml/glossary/what-is-a-qiskit-session/"
  }, "Qiskit sessions"), "."), "\n", React.createElement(_components.p, null, "To learn more about Qiskit Runtime, you can visit the ", React.createElement(_components.a, {
    href: "https://cloud.ibm.com/quantum"
  }, "IBM Cloud homepage"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
